import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "ようこそ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#%E3%82%88%E3%81%86%E3%81%93%E3%81%9D",
        "aria-label": "ようこそ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ようこそ`}</h1>
    <p>{`本オンラインブックはセキュリティ・キャンプ全国大会 2021 オンラインの講義`}<a parentName="p" {...{
        "href": "https://www.ipa.go.jp/jinzai/camp/2021/zenkoku2021_program_list.html#list_b1"
      }}>{`「ちいさな Web ブラウザを作ってみよう」`}</a>{` の事前課題資料です。`}</p>
    <h2 {...{
      "id": "同講義の概要",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%90%8C%E8%AC%9B%E7%BE%A9%E3%81%AE%E6%A6%82%E8%A6%81",
        "aria-label": "同講義の概要 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`同講義の概要`}</h2>
    <p>{`Web ブラウザが持つセキュリティ機構や、Web ブラウザ内で行われる先鋭的な攻撃手法の研究は発展を続けています。
この流れに沿って、それらの中には、Web ブラウザの内部構造への理解が強く求められるようなものも登場してきています。`}</p>
    <p>{`そこで同講義では、現代の Web ブラウザの基礎的な機能群が具体的にどのように実装されているかを、既存の Web ブラウザ実装を例に概説します。
これに加えて、TUI ベースの小さな Web ブラウザを作成する演習を行います。
これらは Web 標準を眺めるだけでは得られない細かな知見を主体的に獲得してもらうことを目指すものです。`}</p>
    <p>{`これらの座学や演習により、ぜひ現代の Web クライアントサイドのセキュリティをより深く理解するための強靭な足腰を獲得してください。`}</p>
    <h2 {...{
      "id": "タイトルに-wip-がついたページについて",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%BF%E3%82%A4%E3%83%88%E3%83%AB%E3%81%AB-wip-%E3%81%8C%E3%81%A4%E3%81%84%E3%81%9F%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6",
        "aria-label": "タイトルに wip がついたページについて permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`タイトルに `}<inlineCode parentName="h2">{`[WIP]`}</inlineCode>{` がついたページについて`}</h2>
    <p>{`タイトルに `}<inlineCode parentName="p">{`[WIP]`}</inlineCode>{` がついたページは現在執筆途中のものです。
`}<strong parentName="p">{`更新がある度に `}<a parentName="strong" {...{
          "href": "https://twitter.com/lmt_swallow"
        }}>{`著者の Twitter アカウント`}</a>{` でその旨ツイートします`}</strong>{`ので、気になる方はこちらのアカウントを都度ご確認ください。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      